.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

.logo {
  width: 200px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mtop40 {
  margin-top: 40px;
}

#sidebar {
  height: 100%; /* 100% Full-height */
  width: 250px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 60; /* Stay at the top */
  left: 0;
  background-color: #818181; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

#sidebar p {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

#sidebar p:hover {
  color: #f1f1f1;
}

.Main-content {
  width: 100%;
  padding-left: 250px;
}

.Phone-container {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  border: solid 10px #f4f4f4;
  border-radius: 4px;
  padding: 1em;
  text-align: center;
  top: 40px;
  z-index: 100;
}

.Circle-button-green {
  background-color: green;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.Circle-button-red{
  background-color: red;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 34px;
  margin-left: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  border-radius: 34px;
  width: 60px;
}

.slider.round:before {
  border-radius: 50%;
}

.online-label {
  color: #fff;
  margin-left: 80px;
  position: relative;
  font-size: 18px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.dummy {}

.audio-control {
  margin-left: 10px;
  position: relative;
  top: 4px;
  height: 20px;
}

.reload-audio: {
  position: relative;
  top: -1px;
  cursor: pointer;  
}

.pagenation {
  margin-top: 40px;
  text-align: right;
}

.pagenation span {
  cursor: pointer;
  text-decoration: underline;
}

.timeSelect {
  width: 100px;
}
